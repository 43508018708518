import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import QuoteForm from "@containers/translation-quote-form";
import FeatureGrid from "@components/feature-grid";
import IntroAreaOne from "@components/introarea/layout-4";
import IntroArea from "@components/introarea/layout-6";
import TopTier from "@containers/top-tier";
import IntroAreaReverse from "@components/introareareverse/layout-5";
import LocalizeGrid from "@components/localize-grid/layout-4";
import BoxSection from "@components/BoxSection/layout-one/layout-1";
import CaseStudy from "@containers/global/case-study/layout-01";
import { caseStudiesData } from "@constants";
import ContactArea from "@containers/contact-us";
const MultilingualContentGenerationPage = ({ location, data }) => {
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);

  return (
    <Layout location={location}>
      <Seo
        title="Multilingual Content Generation"
        description="Transforming ideas into multilingual masterpieces across 100+ languages with Andovar's human-first approach"
        />
      <Header
        data={{
          ...globalContent["menu"],
          ...data.site.siteMetadata,
        }}
      />
      <main className="site-wrapper-reveal">
        <PageHeader data={content["multilingual-content-solutions-header"]} />
        <QuoteForm />
        <FeatureGrid data={content["multilingual-content-solutions-features-data"]} />
        <IntroAreaOne layout={4} data={content["multilingual-content-solutions-intro-data"]} />
        <IntroArea layout={3} data={content["hight-quality-user-first-data"]} />
        <TopTier data={content["multilingual-content-solutions-languages-data"]} />
        <IntroAreaReverse layout={6} data={content["multilingual-content-solutions-use-cases-data"]} />
        <LocalizeGrid layout={1}  data={content["multilingual-content-solutions-benefits-data"]} />
        <BoxSection layout={5} data={content["multilingual-content-solutions-processes-data"]} />
        <CaseStudy layout={3} data={caseStudiesData} />
        <ContactArea Layout={2} />
      </main>
      <Footer data={{ ...data.site.siteMetadata }} />
    </Layout>
  );
};

export const query = graphql`
  query MultilingualContentGenerationPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(title: { eq: "multilingual-content-solutions" }, pageType: { eq: "innerpage" }) {
      content {
        ...PageContent
      }
    }
  }
`;

MultilingualContentGenerationPage.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default MultilingualContentGenerationPage;
